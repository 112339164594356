'use client'

import types from '@havppen/gql/types'
import React from 'react'
import { SEOHead } from 'src/components/global/SEOWrapper'
import { GetPropsFunc } from 'src/components/hoc'
import DefaultLayout from 'src/components/layouts/DefaultLayout'
import PageResolver from 'src/components/pageBuilder/PageResolver'
import { useApp } from 'src/contexts/AppContext'
import { prefetchCustomPageData } from 'src/helpers/customPage'
import { GET_PAGES, useCustomHomePageData } from 'src/hooks/page'
import { PageProps } from 'src/types/shared'

const CustomDragHomepage: React.FC<PageProps> = () => {
  const { pageData } = useCustomHomePageData()

  const { name: appName, settings } = useApp()
  const homepageTitle = settings['seo.og_title'] ?? appName

  return (
    <DefaultLayout>
      <PageResolver data={pageData?.data ?? ''} />
      <SEOHead seoOptions={{ title: homepageTitle }} />
    </DefaultLayout>
  )
}

export const getStaticProps: GetPropsFunc = async (context, { apolloClient, appId }) => {
  let revalidate: number | undefined = undefined
  let isCustomPage = false
  const isPreview = context.preview ?? null
  if (appId) {
    const { data: page_data } = await apolloClient.query<types.GET_PAGES, types.GET_PAGESVariables>({
      query: GET_PAGES,
      variables: {
        condition: {
          app_id: { _eq: appId },
          type: { _eq: 'customHome' },
          mode: { _eq: 'drag' },
          published_at: isPreview ? undefined : { _is_null: false },
        },
      },
      context: {
        fetchOptions: {
          next: {
            revalidate: false,
            tags: [`page:${appId}_customHome`],
          },
        },
      },
    })
    if (page_data && page_data.page.length > 0) {
      const page = page_data.page[0]
      await prefetchCustomPageData(page.id, page.data || '{}', appId, apolloClient)
      isCustomPage = true
      revalidate = 60
    }
  }

  return {
    revalidate,
    props: {
      isPreview,
      isCustomPage,
    },
  }
}

export default CustomDragHomepage
