import { gql, useMutation, useQuery } from '@apollo/client'
import types from '@havppen/gql/types'
import { CollectableProductType } from '@havppen/types/src/product'
import { useAuth } from 'src/contexts/AuthContext'
import { NIL as NIL_UUID } from 'uuid'

export const useMemberCollectionProductIds = (options: { memberId: string; productType: CollectableProductType }) => {
  const { loading, error, data, refetch } = useQuery<
    types.GET_MEMBER_PRODUCT_COLLECTIONS,
    types.GET_MEMBER_PRODUCT_COLLECTIONSVariables
  >(
    gql`
      query GET_MEMBER_PRODUCT_COLLECTIONS($memberId: String!, $productType: String!) {
        member_product_collection(where: { member_id: { _eq: $memberId }, product: { type: { _eq: $productType } } }) {
          product_id
        }
      }
    `,
    {
      variables: {
        memberId: options.memberId,
        productType: options.productType,
      },
    },
  )

  const memberCollectionProductIds: string[] =
    loading || error || !data
      ? []
      : data.member_product_collection.map(member_product_collection => member_product_collection.product_id)
  return {
    loadingMemberCollectionProductIds: loading,
    errorMemberCollectionProductIds: error,
    memberCollectionProductIds,
    refetchMemberCollectionProductIds: refetch,
  }
}

export const useMemberProductCollectionCount = (options: {
  memberId?: string
  productType?: CollectableProductType
  productId?: string
}) => {
  const { loading, error, data, refetch } = useQuery<
    types.GET_MEMBER_PRODUCT_COLLECTION_COUNT,
    types.GET_MEMBER_PRODUCT_COLLECTION_COUNTVariables
  >(
    gql`
      query GET_MEMBER_PRODUCT_COLLECTION_COUNT($condition: member_product_collection_bool_exp!) {
        member_product_collection_aggregate(where: $condition) {
          aggregate {
            count
          }
        }
      }
    `,
    {
      skip: options.memberId === NIL_UUID,
      variables: {
        condition: {
          member_id: options.memberId ? { _eq: options.memberId } : undefined,
          product: options.productType ? { type: { _eq: options.productType } } : undefined,
          product_id: options.productId ? { _eq: options.productId } : undefined,
        },
      },
    },
  )

  const memberProductCollectionCount =
    loading || error || !data ? 0 : data.member_product_collection_aggregate.aggregate?.count || 0
  return {
    loadingMemberProductCollectionCount: loading,
    errorMemberProductCollectionCount: error,
    memberProductCollectionCount,
    refetchMemberProductCollectionCount: refetch,
  }
}

export const useIsProductInMemberCollection = (productId: string) => {
  const { currentMemberId } = useAuth()
  const { loading, error, data, refetch } = useQuery<
    types.GET_MEMBER_PRODUCT_COLLECTION,
    types.GET_MEMBER_PRODUCT_COLLECTIONVariables
  >(GET_MEMBER_PRODUCT_COLLECTION, {
    skip: currentMemberId === NIL_UUID,
    variables: {
      productId,
      memberId: currentMemberId,
    },
  })

  const isProductInMemberCollection =
    loading || error || !data || !data.member_product_collection_by_pk
      ? false
      : Boolean(data.member_product_collection_by_pk.member_id)

  return {
    loadingIsProductInMemberCollection: loading,
    errorIsProductInMemberCollection: error,
    isProductInMemberCollection,
    refetchIsProductInMemberCollection: refetch,
  }
}

export const useMemberProductCollectionMutation = (productId: string) => {
  const { currentMemberId } = useAuth()

  const [insertMemberProductCollectionHandler] = useMutation<
    types.INSERT_MEMBER_PRODUCT_COLLECTION,
    types.INSERT_MEMBER_PRODUCT_COLLECTIONVariables
  >(
    gql`
      mutation INSERT_MEMBER_PRODUCT_COLLECTION($productId: String!) {
        insert_member_product_collection_one(
          object: { product_id: $productId }
          on_conflict: { constraint: member_product_collection_pkey, update_columns: [product_id] }
        ) {
          member_id
          product_id
        }
      }
    `,
    {
      optimisticResponse: {
        insert_member_product_collection_one: currentMemberId
          ? {
              product_id: productId,
              member_id: currentMemberId,
              __typename: 'member_product_collection',
            }
          : null,
      },
      update(cache, { data }) {
        cache.writeQuery<types.GET_MEMBER_PRODUCT_COLLECTION, types.GET_MEMBER_PRODUCT_COLLECTIONVariables>({
          query: GET_MEMBER_PRODUCT_COLLECTION,
          data: {
            member_product_collection_by_pk: data?.insert_member_product_collection_one
              ? {
                  product_id: data.insert_member_product_collection_one.product_id,
                  member_id: data.insert_member_product_collection_one.member_id,
                  __typename: 'member_product_collection',
                }
              : null,
          },
          variables: {
            productId,
            memberId: data?.insert_member_product_collection_one?.member_id || '',
          },
        })
      },
    },
  )
  const insertMemberProductCollection = () => {
    return insertMemberProductCollectionHandler({
      variables: { productId },
    })
  }

  const [deleteMemberProductCollectionHandler] = useMutation<
    types.DELETE_MEMBER_PRODUCT_COLLECTION,
    types.DELETE_MEMBER_PRODUCT_COLLECTIONVariables
  >(
    gql`
      mutation DELETE_MEMBER_PRODUCT_COLLECTION($productId: String!, $memberId: String!) {
        delete_member_product_collection_by_pk(product_id: $productId, member_id: $memberId) {
          member_id
          product_id
        }
      }
    `,
    {
      optimisticResponse: {
        delete_member_product_collection_by_pk: currentMemberId
          ? {
              product_id: productId,
              member_id: currentMemberId,
              __typename: 'member_product_collection',
            }
          : null,
      },
      update(cache, { data }) {
        cache.writeQuery<types.GET_MEMBER_PRODUCT_COLLECTION, types.GET_MEMBER_PRODUCT_COLLECTIONVariables>({
          query: GET_MEMBER_PRODUCT_COLLECTION,
          data: {
            member_product_collection_by_pk: null,
          },
          variables: {
            productId: data?.delete_member_product_collection_by_pk?.product_id || '',
            memberId: data?.delete_member_product_collection_by_pk?.member_id || '',
          },
        })
      },
    },
  )
  const deleteMemberProductCollection = () => {
    return deleteMemberProductCollectionHandler({
      variables: { productId, memberId: currentMemberId },
    })
  }

  return {
    insertMemberProductCollection,
    deleteMemberProductCollection,
  }
}

const GET_MEMBER_PRODUCT_COLLECTION = gql`
  query GET_MEMBER_PRODUCT_COLLECTION($memberId: String!, $productId: String!) {
    member_product_collection_by_pk(member_id: $memberId, product_id: $productId) {
      member_id
      product_id
    }
  }
`
