'use client'

import { Spin } from 'antd'
import React from 'react'
import styled from 'styled-components'

const StyledLoadingSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`
const StyledSpin = styled(Spin)<{ color?: string | null }>`
  color: ${props => props.color || props.theme['primary-color']} !important;
  .ant-spin-dot-item {
    background-color: ${props => props.color || props.theme['primary-color']} !important;
  }
`

const FullLoadingItem: React.FC = () => {
  return (
    <StyledLoadingSection>
      <StyledSpin size="large" />
    </StyledLoadingSection>
  )
}

export default FullLoadingItem
