'use client'

import { ClockCircleOutlined, UserOutlined } from '@ant-design/icons'
import { ProductStatus, ProductType } from '@havppen/types/src/product'
import { Progress, Space } from 'antd'
import clsx from 'clsx'
import dayjs from 'dayjs'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import Link from 'next/link'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { StyledLabelTag } from 'src/components/common'
import { useApp } from 'src/contexts/AppContext'
import { CustomThemeContext } from 'src/contexts/CustomThemeContext'
import { BREAK_POINT_SM } from 'src/contexts/ResponsiveContext'
import { UTMParams } from 'src/types/common'
import { MemberPublicBriefProps } from 'src/types/member'
import styled from 'styled-components'
import FavoriteButton from './FavoriteButton'
import InstructorsAvatarBox from './InstructorsAvatarBox'

const Rating = dynamic(() => import('../common/Rating'), { ssr: false })

const StyledProductCoverDiv = styled.div`
  position: relative;

  .image-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    box-shadow: 0 0 4px 0 var(--theme-box-shadow-color);
    img {
      object-fit: cover;
    }

    &.logo img {
      object-fit: contain;
    }
  }

  .status-label {
    position: absolute;
    top: 0rem;
    right: 1.25rem;
  }
  .duration-label {
    position: absolute;
    right: 1.25rem;
    bottom: 8px;
  }
`

const StyledDurationLabel = styled.div`
  color: #fff;
  background-color: #000;
  border: 1px solid #fff;

  display: inline-flex;
  align-items: center;
  border-radius: 25px;
  bottom: 10px;
  padding: 4px 12px;

  opacity: 0.8;
`

export const ProductCover: React.FC<{
  imageUrl: string | null
  imageAlt: string
  duration?: number
  priority?: boolean
  status: ProductStatus
  startAt?: Date | null
  endAt?: Date
  startSellAt?: Date | null
}> = ({ imageUrl, imageAlt, duration, priority, status, startAt, startSellAt, endAt }) => {
  const { enabledModules } = useApp()
  const { logoUrl } = useApp()

  const featuredImageUrl = imageUrl ?? logoUrl
  const sessionStatus = useMemo(() => {
    if (!startAt) return null

    const now = new Date()
    if (now < startAt) {
      return 'upcoming'
    } else if (endAt && now < endAt) {
      return 'progressing'
    } else {
      return 'expired'
    }
  }, [endAt, startAt])

  return (
    <StyledProductCoverDiv>
      <div
        className={clsx('image-wrapper', {
          logo: !imageUrl,
        })}
      >
        {featuredImageUrl && (
          <Image
            src={featuredImageUrl}
            alt={imageAlt}
            fill
            quality={100}
            priority={priority}
            sizes="(max-width: 768px) 260px, 255px"
            unoptimized={featuredImageUrl.endsWith('.svg') || featuredImageUrl.endsWith('.gif')}
          />
        )}
      </div>

      <div className="status-label">
        <Space>
          {enabledModules.fundraising && (
            <StyledLabelTag status={status === 'fundraising' || status === 'preorder' ? 'fundraising' : 'default'}>
              <FormattedMessage id={`product.status.${status}`} defaultMessage={status} />
            </StyledLabelTag>
          )}

          {sessionStatus && (
            <StyledLabelTag>
              <FormattedMessage id={`activity.sessionStatus.${sessionStatus}`} defaultMessage={sessionStatus} />
            </StyledLabelTag>
          )}
        </Space>
      </div>

      {duration !== undefined && duration > 0 && (
        <div className="duration-label">
          <StyledDurationLabel>
            <ClockCircleOutlined />
            <span className="ml-2">
              <FormattedMessage
                id="course.information.totalCourseTimeHourMinute"
                values={{
                  hours: Math.floor(duration / 60),
                  minutes: Math.round(duration % 60),
                }}
              />
            </span>
          </StyledDurationLabel>
        </div>
      )}
    </StyledProductCoverDiv>
  )
}

const StyledProductCardContent = styled.div`
  color: var(--theme-secondary-color);
  font-size: 12px;
  margin-bottom: 12px;
  .price-tag {
    font-size: 20px;
    font-weight: 500;
  }
  .list-price-tag {
    text-decoration: line-through;
    font-size: 12px;
    color: var(--theme-secondary-color);
  }
  .highlight {
    color: var(--theme-primary-color);
  }
  .ant-rate-star:not(:last-child) {
    margin-right: 1px;
  }
`
export const ProductCardContent: React.FC<{
  listPrice: number
  salePrice?: number | null
  reviewCount?: number | null
  averageRating?: number | null
  purchaseCount?: number
  totalSales?: number
  isPurchased?: boolean
  isOpenForSale?: boolean
  isPriceHidden?: boolean
  isParticipantCountHidden?: boolean
  isDiscountCountdownVisible?: boolean
  openForSaleText?: string | React.ReactNode
  totalProgressPercentage?: number | null
  expiredAt?: Date | null
}> = ({
  listPrice,
  salePrice,
  reviewCount,
  averageRating,
  purchaseCount = 0,
  isPurchased = false,
  isOpenForSale = true,
  isPriceHidden = false,
  isParticipantCountHidden = false,
  isDiscountCountdownVisible = false,
  openForSaleText,
  totalProgressPercentage,
  expiredAt,
}) => {
  const { theme } = useContext(CustomThemeContext)
  const primaryColor = theme['primary-color'] ?? undefined
  const percentage = useMemo(() => Math.ceil((totalProgressPercentage ?? 0) * 100), [totalProgressPercentage])
  const isExpired = useMemo(() => (expiredAt ? expiredAt.getTime() < Date.now() : false), [expiredAt])

  return (
    <StyledProductCardContent>
      {isPurchased ? (
        <div>
          <Progress percent={percentage} strokeColor={primaryColor} showInfo={false} />
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div>{percentage}% 完成</div>
          </div>

          <div className="highlight">
            {expiredAt ? (
              isExpired ? (
                <span>已過期</span>
              ) : (
                <FormattedMessage
                  id="course.information.expiredAt"
                  defaultMessage="有效觀看期限：{expiredAt}"
                  values={{
                    expiredAt: dayjs(expiredAt).format('YYYY-MM-DD'),
                  }}
                />
              )
            ) : (
              <span>無觀看期限限制</span>
            )}
          </div>
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center mb-2">
            {typeof reviewCount === 'number' && typeof averageRating === 'number' && reviewCount > 0 && (
              <span className="flex-grow-1">
                <Rating defaultValue={averageRating} count={reviewCount} size="small" />
              </span>
            )}

            {!isPriceHidden && (
              <div className="d-flex flex-column align-items-end">
                {salePrice !== null && salePrice !== undefined && salePrice < listPrice && (
                  <div className="list-price-tag ml-1">NT${listPrice.toLocaleString()}</div>
                )}
                <div className="price-tag highlight">NT${(salePrice ?? listPrice).toLocaleString()}</div>
              </div>
            )}
          </div>

          <div className="d-flex justify-content-between align-items-center mt-1">
            {!isParticipantCountHidden && (
              <span>
                <span>
                  <UserOutlined />
                  <span className="ml-1">{purchaseCount}人購買</span>
                </span>
              </span>
            )}

            {isOpenForSale && <span className="highlight">{openForSaleText}</span>}
          </div>
        </>
      )}
    </StyledProductCardContent>
  )
}

const StyledProductCard = styled.div`
  border-radius: var(--default-border-radius, 16px);
  overflow: hidden;
  background-color: var(--theme-component-background-color);
  box-shadow: var(--theme-box-shadow);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: var(--card-max-width);
  margin: 0 auto;

  &.is-expired {
    opacity: 0.5;
    &,
    .card-title,
    .card-content {
      cursor: not-allowed;
    }
  }

  .favorite {
    position: absolute;
    top: 8px;
    left: 8px;
  }

  .card-title {
    padding: 12px 20px 0 20px;

    .title {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.5;
      color: var(--theme-title-color);

      @media screen and (min-width: ${BREAK_POINT_SM}px) {
        font-size: 18px;
      }
    }

    .datetime {
      font-size: 14px;
      color: var(--theme-secondary-color);
    }
  }

  .card-body {
    padding: 0 20px 12px 20px;
  }
`

export type ProductCardCommonProps = {
  showFavorite?: boolean
  openInNewTab?: boolean
  utmParams?: UTMParams
  isPurchased?: boolean
  expiredAt?: Date | null
}
const ProductCard: React.FC<
  React.PropsWithChildren<
    ProductCardCommonProps & {
      priority?: boolean
      productType: ProductType
      productTarget: string
      productStatus: ProductStatus
      path: string
      title: string
      featuredImageUrl: string | null
      startAt?: Date | null
      endAt?: Date
      startSellAt?: Date | null
      duration?: number
      members: MemberPublicBriefProps[]
      isAuthorInfoHidden?: boolean
      isVisible?: boolean
    }
  >
> = ({
  priority,
  productType,
  productTarget,
  productStatus,
  title,
  path,
  featuredImageUrl,
  startAt,
  endAt,
  startSellAt,
  duration,
  members,
  showFavorite,
  openInNewTab,
  utmParams,
  isAuthorInfoHidden,
  expiredAt,
  children,
  isVisible,
}) => {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    setLoaded(true)
  }, [])

  const isExpired = useMemo(() => (expiredAt ? expiredAt.getTime() < Date.now() : false), [expiredAt])
  const productId = useMemo(() => `${productType}_${productTarget}`, [productType, productTarget])
  const linkSrc = useMemo(
    () => (utmParams ? `${path}?${new URLSearchParams(utmParams).toString()}` : path),
    [path, utmParams],
  )

  const onClickProductCard: React.MouseEventHandler<HTMLAnchorElement> = useCallback(
    e => {
      if (isExpired) {
        e.preventDefault()
        return
      }

      window.sendEvent?.('select_item', {
        product_id: productId,
        items: [{ item_id: productId, item_name: title }],
      })
    },
    [isExpired, productId, title],
  )

  const isNotStartedToSell = useMemo(() => {
    if (!startSellAt) return false

    const now = dayjs()
    return dayjs(startSellAt).isAfter(now)
  }, [startSellAt])

  return (
    <StyledProductCard
      className={clsx({
        'is-expired': isExpired,
      })}
      // TODO: uncomment this when Group limitation features is fully released
      // style={{ display: isVisible !== undefined ? (isVisible ? 'flex' : 'none') : 'flex' }}
    >
      <div>
        <Link href={linkSrc} onClick={onClickProductCard} target={openInNewTab ? '_blank' : undefined} passHref>
          <ProductCover
            imageUrl={featuredImageUrl}
            imageAlt={title}
            duration={duration}
            status={productStatus}
            priority={priority}
            startAt={startAt}
            endAt={endAt}
          />
        </Link>

        {showFavorite && (
          <div className="favorite">
            <FavoriteButton productId={productId} />
          </div>
        )}

        <Link href={linkSrc} onClick={onClickProductCard} target={openInNewTab ? '_blank' : undefined} passHref>
          <div className="card-title">
            <div className="mb-2">
              <div className="title">{title}</div>

              {isNotStartedToSell && startSellAt ? (
                <div className="datetime my-2">
                  <span>{dayjs(startSellAt).format('YYYY-MM-DD ddd HH:mm')}</span>
                  <span className="ml-1">開賣</span>
                </div>
              ) : startAt ? (
                <div className="datetime my-2">
                  {loaded ? dayjs(startAt).format('YYYY-MM-DD ddd HH:mm') : dayjs(startAt).format('YYYY-MM-DD HH:mm')}
                </div>
              ) : null}
            </div>
          </div>
        </Link>
      </div>

      <div className="card-body">
        <div>
          {!isAuthorInfoHidden && (
            <InstructorsAvatarBox
              productId={`${productType}_${productTarget}`}
              instructors={members}
              openInNewTab={openInNewTab}
              utmParams={utmParams}
            />
          )}

          <div className="mt-2">
            <Link href={linkSrc} onClick={onClickProductCard} target={openInNewTab ? '_blank' : undefined} passHref>
              <div className="card-content">{children}</div>
            </Link>
          </div>
        </div>
      </div>
    </StyledProductCard>
  )
}

export default ProductCard
