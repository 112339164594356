'use client'

import { isBrightColor } from '@havppen/utils/src/color'
import clsx from 'clsx'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import Link, { LinkProps } from 'next/link'
import React, { memo, useContext, useEffect, useMemo } from 'react'
import { useApp } from 'src/contexts/AppContext'
import { CustomThemeContext } from 'src/contexts/CustomThemeContext'
import PreviewContext from 'src/contexts/PreviewContext'
import { Nav } from 'src/types/app'
import { createGlobalStyle, styled } from 'styled-components'

const getHeader = (appId: string, loading: () => JSX.Element) => {
  const Headers = {
    default: dynamic(() => import('./default'), { ssr: false, loading }),
    havbeat: dynamic(() => import('./havbeat'), { ssr: false, loading }),
    havfit: dynamic(() => import('./havfit'), { ssr: false, loading }),
    coolsaynews: dynamic(() => import('./coolsaynews'), { loading }),
    c2cbuyclass: dynamic(() => import('./c2cbuyclass'), { loading }),
  }

  return Headers[appId as keyof typeof Headers] || Headers.default
}

export type HeaderVariant = 'light' | 'dark'
export type HeaderOptionsProps = {
  variant?: HeaderVariant
  transparent?: boolean
  fixed?: boolean
  visible?: boolean
  showPreview?: boolean
}
export type HeaderProps = HeaderOptionsProps & {
  navItems?: Nav[]
  appName?: string | null
  logoImageUrl?: string | null
}
export type StyledLayoutHeaderProps = {
  $fixed?: boolean
  $transparent?: boolean
  $variant?: HeaderVariant
  $showPreview?: boolean
  className?: string
}

export const StyledLayoutHeader = styled.header`
  z-index: 200 !important;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 64px;

  background: var(--theme-header-background-color) !important;
  box-shadow: var(--theme-box-shadow, 0 0 10px 0 rgb(0 0 0 / 10%));
  color: var(--theme-header-color) !important;

  &,
  .anticon,
  .ant-btn {
    color: var(--theme-header-color) !important;
  }

  &.fixed {
    position: fixed;
    width: 100%;
    z-index: 1;
    top: 0px;
    &.show-preview {
      top: 28px;
    }
  }
  &.hidden {
    height: 0;
    border: 0;
  }

  .logo {
    width: 180px;
    max-width: 180px;
    height: 56px;
    max-height: 56px;
    object-fit: contain;
    object-position: center;
  }
`

export const StyledMenuWrapperDiv = styled.div`
  .menu {
    display: flex;
    align-items: center;
    background: none;
    transition: color 0.2s;
    border-bottom: none;
  }

  .menu {
    &,
    .ant-menu-item {
      color: var(--theme-header-color) !important;
    }
  }
`

const StyledLoadingHeaderDiv = styled.div`
  width: 100%;
  height: 64px;
  border-bottom: 1px solid var(--theme-border-color);
  background-color: var(--theme-header-background-color);
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);

  &.transparent {
    background-color: transparent;
    border-bottom: transparent;
  }

  &.fixed {
    position: fixed;
    width: 100%;
    z-index: 1;
    top: 0px;
    &.preview-mode {
      top: 28px;
    }
  }
`

const HeaderGlobalStyle = createGlobalStyle`
  .header-menu-popup-desktop {
    .ant-menu {
      background-color: var(--theme-header-background-color) !important;
    }
  }
`

const Header: React.FC<HeaderOptionsProps> = props => {
  const { id: appId, name: appName, navs, logoUrl, logoWhiteUrl, settings } = useApp()
  const { isPreviewBarVisible } = useContext(PreviewContext)

  const { isHeaderVisible, isHeaderTransparent, setIsHeaderTransparent } = useContext(CustomThemeContext)
  useEffect(() => {
    if (typeof props.transparent === 'boolean') {
      setIsHeaderTransparent?.(props.transparent)
    }
  }, [props.transparent, setIsHeaderTransparent])

  const navItems = useMemo(() => navs.filter(nav => nav.block === 'header'), [navs])
  const headerLogoUrl = useMemo(() => {
    const headerBackgroundColor = settings['style.@header-background-color'] ?? '#ffffff'
    const isLightBackground = props.variant === 'dark' ? false : isBrightColor(headerBackgroundColor)

    return (isLightBackground ? logoUrl : logoWhiteUrl) ?? logoUrl
  }, [logoUrl, logoWhiteUrl, props.variant, settings])

  const Header = getHeader(appId, () => (
    <StyledLoadingHeaderDiv
      className={clsx({
        fixed: props.fixed,
        'preview-mode': isPreviewBarVisible,
        transparent: props.transparent,
      })}
    ></StyledLoadingHeaderDiv>
  ))
  return (
    <>
      <HeaderGlobalStyle />
      <Header
        visible={isHeaderVisible}
        showPreview={isPreviewBarVisible}
        appName={appName}
        logoImageUrl={headerLogoUrl}
        navItems={navItems}
        {...props}
        transparent={isHeaderTransparent}
      />
    </>
  )
}

const StyledMenuItemDiv = styled.div`
  color: var(--theme-header-color) !important;
  transition: 0.2s opacity;
  &,
  :after {
    border-bottom: none !important;
  }
  &:hover {
    opacity: 0.6;
    img {
      filter: invert(1);
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;

    min-height: 14px;
    max-height: 14px;

    img {
      &[src*='.jpg'],
      &[src*='.png'],
      &[src*='.gif'] {
        object-fit: contain;
      }
      &[src*='.svg'] {
        width: 100%;
        height: 100%;
      }
    }
  }
`

export const NavItem = React.forwardRef<
  HTMLDivElement,
  {
    label: string
    href?: string
    external?: boolean
    icon?: React.ReactNode
    iconUrl?: string | null
    prefetch?: boolean
  } & React.HTMLAttributes<HTMLDivElement>
>(({ label, href, iconUrl, icon, external, prefetch, ...props }, ref) => {
  const targetLink: LinkProps['href'] | undefined = href
    ? href.startsWith('/page/')
      ? {
          pathname: '/page/[pageSlug]',
          query: { pageSlug: href.replace('/page/', '') },
        }
      : href
    : undefined

  return (
    <StyledMenuItemDiv ref={ref} className="d-inline-flex align-items-center" {...props}>
      {targetLink ? (
        <Link
          href={targetLink}
          className="d-flex align-items-center pb-0"
          target={external ? '_blank' : undefined}
          prefetch={prefetch}
        >
          {icon ? (
            <div className="icon" style={{ marginRight: 2 }}>
              {icon}
            </div>
          ) : iconUrl ? (
            <Image
              className="mr-2 mr-lg-1"
              width={16}
              height={16}
              src={iconUrl}
              alt={label}
              unoptimized={iconUrl.endsWith('.svg') || iconUrl.endsWith('.gif')}
            />
          ) : null}
          <span style={{ ...props.style }}>{label}</span>
        </Link>
      ) : (
        <>
          {iconUrl && (
            <Image
              className="mr-2 mr-lg-1"
              width={16}
              height={16}
              src={iconUrl}
              alt={label}
              unoptimized={iconUrl.endsWith('.svg') || iconUrl.endsWith('.gif')}
            />
          )}
          <span style={{ ...props.style }}>{label}</span>
        </>
      )}
    </StyledMenuItemDiv>
  )
})

export const MobileNavItem = React.forwardRef<
  HTMLDivElement,
  {
    label: string
    href?: string
    external?: boolean
    icon?: React.ReactNode
    iconUrl?: string | null
    prefetch?: boolean
  } & React.HTMLAttributes<HTMLDivElement>
>(({ label, href, iconUrl, icon, external, prefetch, ...props }, ref) => {
  const targetLink = href
    ? href.startsWith('/page/')
      ? {
          pathname: '/page/[pageSlug]',
          query: { pageSlug: href.replace('/page/', '') },
        }
      : href
    : undefined

  return (
    <StyledMenuItemDiv ref={ref} className="d-inline-flex align-items-center" {...props}>
      {icon ? (
        <div className="icon" style={{ marginRight: 8 }}>
          {icon}
        </div>
      ) : iconUrl ? (
        <Image
          style={{ marginRight: 8 }}
          width={16}
          height={16}
          src={iconUrl}
          alt={label}
          unoptimized={iconUrl.endsWith('.svg') || iconUrl.endsWith('.gif')}
        />
      ) : (
        <div style={{ width: 24 }}></div>
      )}

      {targetLink ? (
        <Link href={targetLink} target={external ? '_blank' : undefined} prefetch={prefetch}>
          {label}
        </Link>
      ) : (
        <span style={{ ...props.style }}>{label}</span>
      )}
    </StyledMenuItemDiv>
  )
})

export default memo(
  Header,
  (prev, next) =>
    prev.fixed === next.fixed &&
    prev.transparent === next.transparent &&
    prev.variant === next.variant &&
    prev.visible === next.visible,
)
