'use client'

import dynamic from 'next/dynamic'
import React, { memo, useMemo } from 'react'
import { useApp } from 'src/contexts/AppContext'

const AuthModals = {
  default: dynamic(() => import('./default')),
  havfit: dynamic(() => import('./havfit')),
}

export type AuthModalProps = {
  defaultVisible?: boolean
  customTitle?: string
  redirectPathAfterLogin?: string
  renderTrigger?: React.FC<{
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
  }>
  onSuccessfullyLogin?: (isNewMember?: boolean) => void
}
const AuthModal: React.FC<AuthModalProps> = ({ ...props }) => {
  const { id: appId } = useApp()

  const AuthModal = useMemo(() => AuthModals[appId as keyof typeof AuthModals] || AuthModals.default, [appId])
  return <AuthModal {...props} />
}

export const GlobalAuthModal = memo(AuthModal)
export default AuthModal
