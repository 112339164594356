'use client'

import { Layout } from 'antd'
import clsx from 'clsx'
import Image from 'next/image'
import React, { useContext, useMemo } from 'react'
import Footer from 'src/components/common/Footer'
import Header, { HeaderVariant } from 'src/components/common/Header'
import FacebookMessengerEmbed from 'src/components/embed/FacebookMessengerEmbed'
import { useApp } from 'src/contexts/AppContext'
import { CustomThemeContext } from 'src/contexts/CustomThemeContext'
import { styled } from 'styled-components'

const StyledLayout = styled(Layout)`
  position: relative;
  min-height: 100vh !important;
  background: transparent !important;
`
const StyledLayoutContent = styled(Layout.Content)`
  position: relative;
  &.full-height {
    padding-top: 4rem;
    height: 100vh;
  }
  &.fixed-header {
    padding-top: var(--header-height, 65px);
  }
`
const StyledBackgroundImageWrapperDiv = styled.div`
  img {
    object-fit: cover;
    object-position: center;
    z-index: 0;
  }
`

export type DefaultLayoutProps = {
  headerVariant?: HeaderVariant
  headerTransparent?: boolean
  headerFixed?: boolean
  headerVisible?: boolean
  noHeader?: boolean
  noFooter?: boolean
  noPaddingTop?: boolean
}
const DefaultLayout: React.FC<React.PropsWithChildren<DefaultLayoutProps>> = ({
  headerVariant = 'light',
  headerTransparent,
  headerFixed,
  headerVisible,
  noHeader = false,
  noFooter = false,
  noPaddingTop = false,
  children,
}) => {
  const { settings, name: appName } = useApp()
  const backgroundImageUrl: string | undefined = useMemo(() => settings['style.background_image_url'], [settings])
  const isHeaderHidden = useMemo(() => settings['style.is_header_hidden'] === 'true', [settings])
  const isFooterHidden = useMemo(() => settings['style.is_footer_hidden'] === 'true', [settings])

  const { isHeaderVisible, isHeaderTransparent } = useContext(CustomThemeContext)

  return (
    <StyledLayout>
      {backgroundImageUrl && (
        <StyledBackgroundImageWrapperDiv>
          <Image
            src={backgroundImageUrl}
            alt={appName ?? ''}
            fill
            unoptimized={backgroundImageUrl.endsWith('.svg') || backgroundImageUrl.endsWith('.gif')}
          />
        </StyledBackgroundImageWrapperDiv>
      )}

      {!noHeader && !isHeaderHidden && (
        <Header
          variant={headerVariant}
          transparent={headerTransparent || isHeaderTransparent}
          fixed={headerFixed}
          visible={headerVisible}
        />
      )}
      <StyledLayoutContent
        className={clsx('overflow-hidden', {
          'fixed-header': isHeaderVisible && (noPaddingTop ? false : headerFixed),
        })}
      >
        {children}
      </StyledLayoutContent>
      {!noFooter && !isFooterHidden && <Footer />}

      <FacebookMessengerEmbed />
    </StyledLayout>
  )
}

export default DefaultLayout
