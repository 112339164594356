'use client'

import { getTextColorByBackground } from '@havppen/utils/src/color'
import { Input, List } from 'antd'
import { BREAK_POINT } from 'src/contexts/ResponsiveContext'
import styled, { css } from 'styled-components'

export const StyledLogo = styled.img<{ invert?: boolean }>`
  width: 100%;
  ${props =>
    props.invert &&
    css`
      filter: invert(1);
    `}
`

export const MenuWrapper = styled.div`
  padding: 12px 0;
  width: 100vw;
  max-width: 320px;
`

export const StyledMenuList = styled(List)`
  && {
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
`

type StyledTagProps = {
  className?: string
  backgroundColor?: string | undefined
  color?: string | undefined
  size?: number | undefined
  status?: 'default' | 'fundraising' | 'discount'
}
export const StyledTag = styled.div<StyledTagProps>`
  padding: 0.25rem;
  font-size: ${props => props.size || 0.75}rem;

  background: ${props =>
    props.backgroundColor || props.status === 'fundraising'
      ? props.theme['fundraising-color']
      : props.status === 'discount'
      ? 'linear-gradient(to left, #ae4edc, #816bde);'
      : props.theme['primary-color']};

  color: ${props => {
    const backgroundColor =
      props.backgroundColor || props.status === 'fundraising'
        ? props.theme['fundraising-color']
        : props.status === 'discount'
        ? 'linear-gradient(to left, #ae4edc, #816bde);'
        : props.theme['primary-color']

    return getTextColorByBackground(backgroundColor)
  }};
`

export const StyledSearch = styled(Input.Search)`
  .ant-input-affix-wrapper,
  .ant-input-group > .ant-input {
    border-radius: 20px 0 0 20px !important;
  }
  .ant-input {
    border-right: 0px;
    background: transparent;
    padding-left: 16px;

    &::placeholder {
      color: var(--theme-text-color);
    }
  }
  .ant-input-group-addon {
    border-radius: 0 20px 20px 0;
    background: transparent;
  }
  .ant-btn {
    border: 1px solid var(--theme-border-color);
  }
  .ant-input-search-button {
    background: transparent;
    border-radius: 0 20px 20px 0 !important;
    background-color: transparent !important;
    box-shadow: none;

    svg {
      color: var(--theme-text-color);
    }
  }
`

export const StyledLabelTag = styled(StyledTag)`
  padding: 0.25rem 0.5rem;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`

export const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5rem;
  margin-bottom: 1rem;
  color: ${props => (props.color ? props.theme[props.color] : '#000000')};
`

export const SubTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
  color: ${props => props.theme[props.color || 'black-color']};
`

export const backgroundStyles = css`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

export const Card = styled.div<{ backgroundImage?: string; padding?: number }>`
  width: 100%;
  height: 100%;
  padding: ${props => (props.padding === undefined ? 2 : props.padding)}rem;
  border-radius: var(--default-border-radius, 16px);
  margin-bottom: 1rem;
  box-shadow: var(--theme-box-shadow);
  background: #ffffff;
  ${({ backgroundImage }) =>
    backgroundImage &&
    css`
      background-image: url(${backgroundImage});
    `};

  ${props => props.backgroundImage && backgroundStyles}
`

export const StyledTableRowContainer = styled.div<{ width?: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  @media screen and (min-width: ${BREAK_POINT}px) {
    width: ${props => props.width || 280}px;
  }

  .row-head {
    flex: 1;
    text-align: right;
  }
  .row-cell {
    flex: 1;
    text-align: right;
  }
  .cross-out {
    text-decoration: line-through;
  }
`
