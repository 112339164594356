'use client'

import Script from 'next/script'
import React, { memo, useMemo } from 'react'
import { useApp } from 'src/contexts/AppContext'

const FacebookMessengerBlock: React.FC<{ facebookPageId: string }> = ({ facebookPageId }) => {
  return (
    <div>
      <div id="fb-root"></div>

      <div id="fb-customer-chat" className="fb-customerchat"></div>
      <Script strategy="lazyOnload" id="fb-customer-chat-script">
        {`
            var chatbox = document.getElementById('fb-customer-chat');
            chatbox.setAttribute("page_id", "${facebookPageId}");
            chatbox.setAttribute("attribution", "biz_inbox");
      
            window.fbAsyncInit = function() {
              FB.init({
                xfbml            : true,
                version          : 'v12.0'
              });
            };
      
            (function(d, s, id) {
              var js, fjs = d.getElementsByTagName(s)[0];
              if (d.getElementById(id)) return;
              js = d.createElement(s); js.id = id;
              js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
              fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        `}
      </Script>
    </div>
  )
}
const MemoFacebookMessengerBlock = memo(
  FacebookMessengerBlock,
  (prev, next) => prev.facebookPageId === next.facebookPageId,
)

const FacebookMessengerEmbed: React.FC = () => {
  const { settings } = useApp()
  const facebookPageId = useMemo(() => settings['social_api.facebook_page_id'], [settings])
  const isEmbedEnabled = useMemo(() => {
    return facebookPageId && settings['social_api.is_facebook_messenger_embed_enabled'] === 'true'
  }, [facebookPageId, settings])

  if (!isEmbedEnabled) {
    return null
  }

  return <MemoFacebookMessengerBlock facebookPageId={facebookPageId} />
}

export default memo(FacebookMessengerEmbed)
