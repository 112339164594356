import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { APP_GET_ACTIVITIES } from '@havppen/gql/src/activity'
import { APP_GET_APPOINTMENTS } from '@havppen/gql/src/appointment'
import types from '@havppen/gql/types'
import { GET_ARTICLES } from 'src/gql/article'
import { GET_CAROUSEL } from 'src/gql/carousel'
import { GET_COURSES } from 'src/gql/course'
import { GET_MERCHANDISES } from 'src/gql/merchandise'
import { mapActivitiesQueryOptionsToGqlVariables } from 'src/hooks/activity'
import { mapAppointmentsQueryOptionsToGqlVariables } from 'src/hooks/appointment'
import { mapArticlesQueryOptionsToGqlVariables } from 'src/hooks/article'
import { mapCoursesQueryOptionsToGqlVariables } from 'src/hooks/course'
import { mapMerchandisesQueryOptionsToGqlVariables } from 'src/hooks/merchandise'

export const prefetchCustomPageData = async (
  pageId: string,
  data: string,
  appId: string,
  apolloClient: ApolloClient<NormalizedCacheObject>,
) => {
  const queryContext = {
    fetchOptions: {
      next: {
        revalidate: false,
        tags: [`page:${appId}_custom_${pageId}`],
      },
    },
  }

  try {
    const json = JSON.parse(data)
    const promises = Object.values(json).map(async (value: any) => {
      const resolvedName = value?.type?.resolvedName
      const props = value?.props
      if (!resolvedName) return

      switch (resolvedName) {
        case 'CarouselPageSection': {
          const carouselId = props?.carouselId
          if (!carouselId) {
            break
          }

          await apolloClient.query<types.GET_CAROUSEL, types.GET_CAROUSELVariables>({
            query: GET_CAROUSEL,
            context: queryContext,
            variables: {
              condition: {
                id: { _eq: carouselId },
                status: { _eq: 'published' },
                app_id: { _eq: appId },
              },
            },
          })
          break
        }
        case 'CourseListSection': {
          await apolloClient.query<types.GET_COURSES, types.GET_COURSESVariables>({
            query: GET_COURSES,
            context: queryContext,
            variables: mapCoursesQueryOptionsToGqlVariables({ limit: 4 }, { appId }),
          })
          break
        }
        case 'ActivityListSection': {
          await apolloClient.query<types.APP_GET_ACTIVITIES, types.APP_GET_ACTIVITIESVariables>({
            query: APP_GET_ACTIVITIES,
            context: queryContext,
            variables: mapActivitiesQueryOptionsToGqlVariables({ limit: 4, showExpired: true }, { appId }),
          })
          break
        }
        case 'AppointmentListSection': {
          await apolloClient.query<types.APP_GET_APPOINTMENTS, types.APP_GET_APPOINTMENTSVariables>({
            query: APP_GET_APPOINTMENTS,
            context: queryContext,
            variables: mapAppointmentsQueryOptionsToGqlVariables({ limit: 4 }, { appId }),
          })
          break
        }
        case 'MerchandiseListSection': {
          await apolloClient.query<types.GET_MERCHANDISES, types.GET_MERCHANDISESVariables>({
            query: GET_MERCHANDISES,
            context: queryContext,
            variables: mapMerchandisesQueryOptionsToGqlVariables({ limit: 4 }, { appId }),
          })
          break
        }
        case 'ArticleListSection': {
          const articleCategoryId = props?.articleCategoryId
          await apolloClient.query<types.GET_ARTICLES, types.GET_ARTICLESVariables>({
            query: GET_ARTICLES,
            context: queryContext,
            variables: mapArticlesQueryOptionsToGqlVariables(
              { limit: 4, orderBy: 'latest', categoryId: articleCategoryId },
              { appId },
            ),
          })
          break
        }
      }
    })
    await Promise.all(promises)
  } catch (e) {
    console.log(e)
  }
}
