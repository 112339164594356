'use client'

import { Editor, Frame } from '@craftjs/core'
import React from 'react'
import { useElementResolver } from './selectors'

const PageResolver: React.FC<{ data: string }> = ({ data }) => {
  const resolver = useElementResolver()

  return (
    <Editor enabled={false} resolver={resolver}>
      <Frame data={data}></Frame>
    </Editor>
  )
}

export default PageResolver
