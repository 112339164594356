'use client'

import { Avatar } from 'antd'
import Link from 'next/link'
import { UTMParams } from 'src/types/common'
import { MemberPublicBriefProps } from 'src/types/member'
import styled from 'styled-components'
import GeneralAvatar from './GeneralAvatar'

const StyledInstructorsAvatarBoxDiv = styled.div`
  &,
  a {
    color: var(--theme-text-color);
    line-height: 1;
  }

  .ant-avatar {
    max-width: 50px;
    border-radius: 50%;
    display: block;
    overflow: hidden;
  }

  .instructor-names {
    line-height: 1.4;
  }
`

const InstructorsAvatarBox: React.FC<{
  productId: string
  instructors: MemberPublicBriefProps[]
  openInNewTab?: boolean
  utmParams?: UTMParams
}> = ({ productId, instructors, openInNewTab, utmParams }) => {
  const searchString = utmParams ? new URLSearchParams(utmParams).toString() : null

  const onClickInstructor = (memberId: string) => {
    window.sendEvent?.('view_item', {
      content_type: 'instructor',
      content_id: memberId,
      product_id: productId,
    })
  }

  return (
    <StyledInstructorsAvatarBoxDiv className="d-flex align-items-center">
      <Avatar.Group className="mr-2">
        {instructors
          .filter(instructor => !instructor.isPrivate)
          .map(instructor => {
            const path = `/member/${instructor.id}`
            const urlHref = searchString ? `${path}?${searchString}` : path

            return (
              <Link
                key={instructor.id}
                href={urlHref}
                onClick={() => onClickInstructor(instructor.id)}
                target={openInNewTab ? '_blank' : undefined}
              >
                <GeneralAvatar key={instructor.id} src={instructor.avatarUrl} name={instructor.displayName} size={32} />
              </Link>
            )
          })}
      </Avatar.Group>

      <div className="instructor-names">
        {instructors
          .filter(instructor => !instructor.isPrivate)
          .map((instructor, index, filteredInstructors) => {
            const path = `/member/${instructor.id}`
            const urlHref = searchString ? `${path}?${searchString}` : path

            return (
              <Link
                key={instructor.id}
                href={urlHref}
                className="mr-1 d-inline-block"
                onClick={() => onClickInstructor(instructor.id)}
                target={openInNewTab ? '_blank' : undefined}
              >
                <span>{instructor.displayName}</span>
                <span>{filteredInstructors.length - 1 > index ? ',' : ''}</span>
              </Link>
            )
          })}
      </div>
    </StyledInstructorsAvatarBoxDiv>
  )
}

export default InstructorsAvatarBox
