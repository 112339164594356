import { gql, useMutation, useQuery } from '@apollo/client'
import types from '@havppen/gql/types'
import { useCallback, useContext, useMemo } from 'react'
import { useApp } from 'src/contexts/AppContext'
import PreviewContext from 'src/contexts/PreviewContext'
import { validate as isUUID } from 'uuid'

export const pageDataModes = ['text', 'drag']
export const pageDataTypes = ['terms', 'about', 'custom', 'customDrag', 'customHome']
export type PageDataType = typeof pageDataTypes[number]
export type PageDataMode = typeof pageDataModes[number]
export type PageDataProps = {
  id: string
  slug: string | null
  type: PageDataType
  mode: PageDataMode
  title: string
  memberId: string | null
  publishedAt: Date | null
  content: string | null
  data: string | null
  seoAttributes: { [key: string]: string } | null
}

export const usePagesData = (options?: { type?: PageDataType }) => {
  const { id: appId } = useApp()
  const { loading, error, data, refetch } = useQuery<types.GET_PAGES, types.GET_PAGESVariables>(GET_PAGES, {
    variables: {
      condition: {
        type: options?.type ? { _eq: options.type } : undefined,
        app_id: { _eq: appId },
      },
    },
  })

  const pagesData: PageDataProps[] = useMemo(
    () =>
      (data?.page ?? []).map(page => ({
        id: page.id,
        slug: page.slug,
        type: page.type as PageDataType,
        mode: page.mode as PageDataMode,
        title: page.title,
        data: page.data,
        content: page.content,
        memberId: page.member_id,
        publishedAt: page.published_at ? new Date(page.published_at) : null,
        seoAttributes: page.seo_attributes,
      })),
    [data],
  )
  return {
    loadingPagesData: loading,
    errorPagesData: error,
    pagesData,
    refetchPagesData: refetch,
  }
}

export const usePageData = (pageIdOrSlug: string) => {
  const { id: appId } = useApp()
  const { loading, error, data, refetch } = useQuery<types.GET_PAGES, types.GET_PAGESVariables>(GET_PAGES, {
    variables: {
      limit: 1,
      condition: {
        id: isUUID(pageIdOrSlug) ? { _eq: pageIdOrSlug } : undefined,
        slug: isUUID(pageIdOrSlug) ? undefined : { _eq: pageIdOrSlug },
        app_id: { _eq: appId },
      },
    },
  })

  const pageData: PageDataProps | null = useMemo(() => {
    if (!data || data.page.length === 0) {
      return null
    }

    return (data?.page ?? []).map(page => ({
      id: page.id,
      slug: page.slug,
      type: page.type as PageDataType,
      mode: page.mode as PageDataMode,
      title: page.title,
      data: page.data,
      content: page.content,
      memberId: page.member_id,
      publishedAt: page.published_at ? new Date(page.published_at) : null,
      seoAttributes: page.seo_attributes,
    }))[0]
  }, [data])

  return {
    loadingPageData: loading,
    errorPageData: error,
    pageData,
    refetchPageData: refetch,
  }
}

export const useCustomHomePageData = () => {
  const { id: appId } = useApp()
  const { isPreview } = useContext(PreviewContext)
  const { loading, error, data, refetch } = useQuery<types.GET_PAGES, types.GET_PAGESVariables>(GET_PAGES, {
    variables: {
      condition: {
        app_id: { _eq: appId },
        type: { _eq: 'customHome' },
        mode: { _eq: 'drag' },
        published_at: isPreview ? undefined : { _is_null: false },
      },
    },
  })

  const pageData: PageDataProps | null = useMemo(() => {
    if (!data || data.page.length === 0) {
      return null
    }

    return (data?.page ?? []).map(page => ({
      id: page.id,
      slug: page.slug,
      type: page.type as PageDataType,
      mode: page.mode as PageDataMode,
      title: page.title,
      data: page.data,
      content: page.content,
      memberId: page.member_id,
      publishedAt: page.published_at ? new Date(page.published_at) : null,
      seoAttributes: page.seo_attributes,
    }))[0]
  }, [data])

  return {
    loadingPageData: loading,
    errorPageData: error,
    pageData,
    refetchPageData: refetch,
  }
}

export const useUpdatePageData = () => {
  const [updatePageDataHandler] = useMutation<types.UPDATE_PAGE_DATA, types.UPDATE_PAGE_DATAVariables>(gql`
    mutation UPDATE_PAGE_DATA($pageId: uuid!, $object: page_set_input!) {
      update_page_by_pk(pk_columns: { id: $pageId }, _set: $object) {
        id
      }
    }
  `)
  const updatePageData = useCallback(
    (pageId: string, object: types.page_set_input) =>
      updatePageDataHandler({
        variables: { pageId, object },
      }),
    [updatePageDataHandler],
  )

  return updatePageData
}

export const GET_PAGES = gql`
  query GET_PAGES($condition: page_bool_exp!, $limit: Int) {
    page(where: $condition, limit: $limit) {
      id
      slug
      type
      mode
      title
      data
      member_id
      content
      seo_attributes
      published_at
    }
  }
`
