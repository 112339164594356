'use client'

import dynamic from 'next/dynamic'
import { memo, useContext, useEffect, useMemo, useState } from 'react'
import { useApp } from 'src/contexts/AppContext'
import { CustomThemeContext } from 'src/contexts/CustomThemeContext'
import DefaultFooter from './default'

const Footers = {
  default: DefaultFooter,
  havbeat: dynamic(() => import('./havbeat')),
  havfit: dynamic(() => import('./havfit')),
  coolsaynews: dynamic(() => import('./coolsaynews')),
}

const Footer: React.FC = () => {
  const [ref, setRef] = useState<HTMLElement | null>(null)
  const { id: appId } = useApp()

  const { setFooterHeight } = useContext(CustomThemeContext)
  useEffect(() => {
    const onWindowResize = () => {
      if (ref) {
        window.setTimeout(() => {
          setFooterHeight?.(ref.clientHeight)
        }, 100)
      }
    }

    onWindowResize()
    window.addEventListener('resize', onWindowResize, { passive: true })
    return () => {
      window.removeEventListener('resize', onWindowResize)
    }
  }, [ref, setFooterHeight])

  const Footer = useMemo(() => Footers[appId as keyof typeof Footers] || Footers.default, [appId])
  return <Footer footerRef={setRef} />
}

export default memo(Footer)
