'use client'

import { HeartOutlined } from '@ant-design/icons'
import { TrackingEventParams } from '@havppen/types/src/tracking'
import { Button, ButtonProps, message } from 'antd'
import React from 'react'
import { useApp } from 'src/contexts/AppContext'
import { useAuth } from 'src/contexts/AuthContext'
import { useIsProductInMemberCollection, useMemberProductCollectionMutation } from 'src/hooks/productCollection'
import styled from 'styled-components'
import AuthModal from '../auth/AuthModal'

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover,
  &:focus {
    color: #e9329c !important;
    border-color: #e9329c !important;
    svg {
      color: #e9329c;
    }
  }

  border-color: var(--theme-border-color);
  svg {
    color: var(--theme-text-color);
  }

  &.ant-btn-primary {
    background-color: #e9329c !important;
    border-color: #e9329c !important;
    svg {
      color: white;
    }
  }
`

const FavoriteButton: React.FC<React.PropsWithChildren<ButtonProps & { productId: string; className?: string }>> = ({
  productId,
  className,
  children,
  ...props
}) => {
  const { enabledModules, defaultCurrency } = useApp()
  const { isAuthenticated } = useAuth()
  const { isProductInMemberCollection } = useIsProductInMemberCollection(productId)
  const { insertMemberProductCollection, deleteMemberProductCollection } = useMemberProductCollectionMutation(productId)

  const onClickFavorite = async () => {
    try {
      const eventParams: TrackingEventParams = {
        product_id: productId,
        items: [{ item_id: productId }],
      }

      if (isProductInMemberCollection) {
        await deleteMemberProductCollection()
        window.sendEvent?.('add_to_favorite', eventParams)
        window.fbq?.('track', 'AddToWishlist', {
          content_ids: [productId],
          contents: [
            {
              id: productId,
              quantity: 1,
            },
          ],
        })
      } else {
        await insertMemberProductCollection()
        window.sendEvent?.('remove_from_favorite', eventParams)
      }
    } catch (error) {
      message.error(error.message)
    }
  }

  if (!enabledModules.bookmark) {
    return <></>
  }

  return (
    <AuthModal
      onSuccessfullyLogin={onClickFavorite}
      renderTrigger={({ setVisible: setAuthModalVisible }) => (
        <StyledButton
          className={className}
          type={isProductInMemberCollection ? 'primary' : undefined}
          shape="circle"
          icon={<HeartOutlined />}
          {...props}
          onClick={() => {
            if (isAuthenticated) {
              onClickFavorite()
            } else {
              setAuthModalVisible(true)
            }
          }}
        >
          {children}
        </StyledButton>
      )}
    />
  )
}

export default FavoriteButton
