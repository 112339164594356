'use client'

import { withDefaultStaticPaths } from 'src/components/hoc/withDefaultStaticPaths'
import { withStaticHostProps } from 'src/components/hoc/withStaticHostProps'
import { NextPageWithLayout, PageProps } from 'src/types/shared'
import HomePage from 'src/views/HomePage'

const IndexPage: NextPageWithLayout<PageProps> = props => {
  return <HomePage {...props} />
}

export const getStaticPaths = withDefaultStaticPaths
export const getStaticProps = withStaticHostProps(HomePage.getStaticProps)
export default IndexPage
