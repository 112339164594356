'use client'

import dynamic from 'next/dynamic'
import { GetPropsFunc } from 'src/components/hoc'
import { ViewPageProps } from '..'
import { getStaticProps as getCustomPageStaticProps } from './custom'

const HomePage: ViewPageProps = (props = {}) => {
  const Page = dynamic(() => {
    if (props.isCustomPage) {
      return import('./custom')
    }

    switch (props.appId) {
      case 'havbeat':
        return import('./havbeat')
      case 'chester':
        return import('./chester')
      case 'coolsaynews':
        return import('./coolsaynews')
      default:
        switch (props.layoutType) {
          case 'media':
            return import('./media')
          default:
            return import('./default')
        }
    }
  })
  return <Page {...props} />
}

HomePage.getStaticProps = async (context, options) => {
  const revalidate: number | undefined = undefined
  const isPreview = context.preview ?? null
  const layoutType = options.app?.layoutType ?? 'default'

  if (options.appId) {
    try {
      const customPageStaticProps = await getCustomPageStaticProps(context, options)
      if ('props' in customPageStaticProps) {
        const isCustomPage = customPageStaticProps.props.isCustomPage ?? false
        if (isCustomPage) {
          return customPageStaticProps
        }
      }

      const getStaticProps: GetPropsFunc | undefined = await import(`./${options.appId}`)
        .catch(() => import(`./${layoutType}`).catch(() => import('./default')))
        .then(page => page.getStaticProps)
      if (getStaticProps) {
        return await getStaticProps(context, options)
      }
    } catch (err) {}
  }

  return {
    props: { isPreview },
    revalidate,
  }
}

export default HomePage
