'use client'

import { faDiscord } from '@fortawesome/free-brands-svg-icons/faDiscord'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { faMediumM } from '@fortawesome/free-brands-svg-icons/faMediumM'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SocialLinkProps } from '@havppen/types/src/shared'
import { isBrightColor } from '@havppen/utils/src/color'
import dayjs from 'dayjs'
import Image from 'next/legacy/image'
import Link from 'next/link'
import React, { useMemo } from 'react'
import { useApp } from 'src/contexts/AppContext'
import { BREAK_POINT } from 'src/contexts/ResponsiveContext'
import { styled } from 'styled-components'

const StyledFooter = styled.footer`
  position: relative;
  background-color: var(--theme-footer-background-color);
  color: var(--theme-footer-color);

  .logo {
    width: 200px;
    max-width: 200px;
    height: 100px;
    max-height: 100px;
    object-fit: contain;
    object-position: center;
  }

  a {
    font-size: 0.75em;
    color: var(--theme-footer-color);
    @media screen and (min-width: ${BREAK_POINT}px) {
      font-size: 0.9em;
    }
  }

  a.icon {
    font-size: 1.5em;
    color: var(--theme-footer-color);
  }

  .footer-main {
    padding: 30px 0 40px 0;
    @media screen and (min-width: ${BREAK_POINT}px) {
      padding: 20px 0;
    }
  }

  .footer-bottom {
    font-size: 12px;
    text-align: center;
    padding-bottom: 20px;
    line-height: 1.5;

    .footer-message {
      padding-bottom: 4px;
      * {
        color: var(--theme-footer-color);
        margin-bottom: 0;
      }
    }
    a {
      color: var(--theme-link-color, var(--theme-footer-color));
    }
  }
`

const Footer: React.FC<{ footerRef?: React.LegacyRef<HTMLElement> }> = ({ footerRef }) => {
  const { logoUrl, logoWhiteUrl, navs, settings, name: appName } = useApp()

  const footerNavs = useMemo(() => navs.filter(nav => nav.block === 'footer'), [navs])
  const socialLinks: SocialLinkProps[] = useMemo(() => JSON.parse(settings['social.social_links'] ?? '[]'), [settings])

  const isLightBackground = useMemo(
    () => isBrightColor(settings['style.@footer-background-color'] ?? '#ffffff'),
    [settings],
  )
  const footerLogoUrl = (isLightBackground ? logoUrl : logoWhiteUrl) ?? logoUrl

  return (
    <StyledFooter ref={footerRef}>
      <div className="container">
        <div className="footer-main">
          <div className="row d-flex align-items-center">
            <div className="col-lg-3">
              {footerLogoUrl ? (
                <Link href="/" passHref>
                  <div className="d-flex align-items-center justify-content-center justify-content-sm-start">
                    <Image
                      width={120}
                      height={60}
                      src={footerLogoUrl}
                      alt={appName || 'logo'}
                      className="logo"
                      loading="lazy"
                      layout="responsive"
                      unoptimized={footerLogoUrl.endsWith('.svg') || footerLogoUrl.endsWith('.gif')}
                    />
                  </div>
                </Link>
              ) : (
                <Link href="/" passHref>
                  {appName}
                </Link>
              )}
            </div>

            <div className="col-lg-9">
              <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-sm-end">
                <div
                  className="mr-sm-3 mb-3 mb-sm-0 d-flex flex-wrap justify-content-center"
                  style={{ gap: '12px 16px' }}
                >
                  {footerNavs.map(footerNav => (
                    <Link
                      href={footerNav.href}
                      passHref
                      key={footerNav.id}
                      target={footerNav.external ? '_blank' : undefined}
                    >
                      {footerNav.label}
                    </Link>
                  ))}
                </div>

                <div>
                  {socialLinks.map((socialLink, index) => {
                    let icon: null | JSX.Element = null
                    switch (socialLink.type) {
                      case 'twitter':
                        icon = <FontAwesomeIcon icon={faTwitter} className="text-light" />
                        break
                      case 'facebook':
                        icon = <FontAwesomeIcon icon={faFacebookF} className="text-light" />
                        break
                      case 'instagram':
                        icon = <FontAwesomeIcon icon={faInstagram} className="text-light" />
                        break
                      case 'medium':
                        icon = <FontAwesomeIcon icon={faMediumM} className="text-light" />
                        break
                      case 'youtube':
                        icon = <FontAwesomeIcon icon={faYoutube} className="text-light" />
                        break
                      case 'discord':
                        icon = <FontAwesomeIcon icon={faDiscord} className="text-light" />
                        break
                      case 'personal':
                        icon = <FontAwesomeIcon icon={faGlobe} className="text-light" />
                        break
                    }

                    return (
                      <a key={index} className="px-2 icon" target="_blank" rel="noreferrer" href={socialLink.url}>
                        {icon}
                      </a>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center footer-bottom">
          {settings['content.footer_message'] && (
            <div className="container footer-message">
              <div dangerouslySetInnerHTML={{ __html: settings['content.footer_message'] }}></div>
            </div>
          )}

          <div>
            <span>
              © {dayjs().year()} {appName}. All rights reserved.{' '}
            </span>
            <span>
              <span>Powered by</span>
              <span className="ml-1"> </span>
              <a href="http://havppen.com/" target="_blank">
                HAVPPEN
              </a>
            </span>
          </div>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer
